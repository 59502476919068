<template>
  <div id="PricePage">
    <dynamic-title :title="getDynamicTitle"></dynamic-title>

    <div class="PricePage__Top PageMain__Top">
      <site-header></site-header>

      <div class="CurrentSinglePrice">
        <span class="CurrentSinglePrice__Head">ADA Current Price:</span>
        <span class="CurrentSinglePrice__Body">₳1 ADA =
          <span v-if="hasResults">{{ConvertedToData.label}}{{ConvertedToData.ratio.toFixed(8)}} {{ConvertedToData.currency}}</span>
          <span v-else>...</span>
        </span>
        <!-- <p>ADA's current price in {{ConvertedToData.currency}}: 
          <span v-if="hasResults"><br>{{ConvertedToData.label}}{{ConvertedToData.ratio.toFixed(10)}}</span>
          <span v-else><br>...</span>
        </p> -->
      </div>

      <div class="CalculatorRefresh">
        <a @click="fetchData" class="CalculatorRefresh__Manual Util-point">Click Refresh</a>
        <span class="CalculatorRefresh__Auto">
          <label for="auto-refresh">Auto-Refresh </label>
          <input type="checkbox" :checked="Price.autoRefresh" @input="toggleAutoRefresh" id="auto-refresh">
        </span>
      </div>

      <div class="CalculatorInputs">
        <!-- Don't ever add: 'type=number' or measure period '.' is not possible -->
        <label for="adaAmountInput" class="CalculatorInputs__Label">ADA</label>
        <input v-model="adaAmount" placeholder="ADA amount..." v-on:keypress="isNumber($event)" id="adaAmountInput" class="CalculatorInputs__ADA">
        <label for="convertToAmountInput" class="CalculatorInputs__Label">{{this.Price.currency}}</label> <!-- using instead of ConvertedToData.currency initial load or API failure-->
        <input v-model="convAmount" :placeholder="convInputPlaceholder" v-on:keypress="isNumber($event)" id="convertToAmountInput" class="CalculatorInputs__Converted">

        <span class="Util-bold">Select Currency:</span>
        <multiselect
          v-model="targetCurrency"
          :searchable="true"
          :close-on-select="true"
          :options="getSelectOptions"
          group-label="group"
          group-values="items"
          :group-select="false"
          track-by="currency"
          label="currency"
          :option-height="25"
          :custom-label="customLabel"
          :show-labels="false"
          :allow-empty="false"
          placeholder="Type to search..."
          maxHeight=188
        >
          <template slot="singleLabel" slot-scope="props">
            <img class="option__image" :src=" '/currency-icons/' + props.option.currency + '.png' " alt="">
            <span class="option__desc">
              <span class="option__title">{{ props.option.currency }} - {{ props.option.name }}</span>
            </span>
          </template>
        
          <template slot="option" slot-scope="props">
            <!-- https://github.com/shentao/vue-multiselect/issues/761#issuecomment-399146476 -->
            <span v-if="props.option.$isLabel">{{ props.option.$groupLabel }}</span>
            <span v-else class="option__wrapper">
              <img class="option__image" :src=" '/currency-icons/' + props.option.currency + '.png' " alt="">
              <div class="option__desc">
                <span class="option__title">{{ props.option.currency }} - {{ props.option.name }}</span>
                <!-- <span class="option__small"></span> -->
              </div>
            </span>
          </template>
          <span slot="noResult">Poops! No currencies match</span>
        </multiselect>

      </div><!--end #CalculatorInputs -->
  
    </div><!--end .PricePage__Top -->

  </div> <!--end #PricePage -->

</template>

<script>
/* 
 * @ is an alias to /src EG:
 * import HelloWorld from '@/components/Price/HelloWorld.vue';
 */

import Multiselect from 'vue-multiselect'

import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
// import { mapMutations } from 'vuex'
// import { mapActions } from 'vuex'

export default {
  components: { Multiselect },

  data: () => {
    return {
      autoRefreshHandle: null,
    }
  },

  mounted () {
    this.fetchData();
    this.autoFetchData(true); // initiate autorefresh
  },

  computed: {
    getSelectOptions () {
      let obj = [
        {
          group: 'Cryptocurrencies',
          items: this.getCryptoCurrencies
        },
        {
          group: 'Fiat Money',
          items: this.getFiatCurrencies
        },
      ]
      return obj
    },
    adaAmount: {
      get () {
        let hasDecimal = this.Price.adaAmount.toString().indexOf(".") != -1;
        if(this.Price.adaAmount > 0 || hasDecimal) { // Only reset input to '' if value = 0 or no decimal (period '.') is present
          if(this.Price.isUpdateAda === false) { // Allow unlimited decimal place input if user typing in adaAmount input
            return this.Price.adaAmount.toString();
          }
          else {
            return this.adaAmountFixedDec; // Limit decimal places if user typing in convAmount input
          }
        }
        else { // Instead of '0' being displayed in input, show placeholder
          return '';
        }
      },
      set (value) {
        this.$store.commit('setIsUpdateAda', false);
        this.$store.commit('setAdaAmount', value);
        setTimeout( () => { // Adds delay effect
          this.$store.commit('updateConvAmount', value);
        },200);
        // this.$store.commit('updateConvAmount', value); // No delay
      }
    },
    convAmount: {
      get () {
        let hasDecimal = this.Price.convAmount.toString().indexOf(".") != -1;
        if(this.Price.convAmount > 0 || hasDecimal) { // Only reset input to '' if value = 0 or no decimal (period '.') is present
          if(this.Price.isUpdateAda === true) { // Allow unlimited decimal place input if user typing in convAmount input
            return this.Price.convAmount;
          }
          else {
            return this.convAmountFixedDec; // Limit decimal places if user typing in adaAmount input
          }
        }
        else { // Instead of '0' being displayed in input, show placeholder
          return '';
        }
      },
      set (value) {
        this.$store.commit('setIsUpdateAda', true);
        this.$store.commit('setConvAmount', value);
        setTimeout( () => { // Adds delay effect
          this.$store.commit('updateAdaAmount', value);
        },200);
        //this.$store.commit('updateAdaAmount', value); // No delay
      }
    },
    targetCurrency: {
      get () {
        return { name: this.ConvertedToData.name, currency: this.Price.currency }
      },
      set (value) {
        this.$store.commit('setCurrency', value.currency);
      }
    },
    adaAmountFixedDec() {
      return this.getFixedDecimalValue(this.Price.adaAmount, 8);
    },
    convAmountFixedDec() {
      return this.getFixedDecimalValue(this.Price.convAmount, 6);
    },
    convRatioFixedDec() {
      return this.getFixedDecimalValue(this.ConvertedToData.ratio, 6);
    },
    hasResults () {
      if(! this.Price.isFetching && this.Price.apiData.currencies.length > 1) {
        return true;
      } else { return false; }
    },
    getDynamicTitle() {
      let constTitle = ' | ADA/' + this.ConvertedToData.currency + ' | AdaCalculator.com';
      if(this.Price.convAmount) {
        return this.convAmountFixedDec + constTitle;
      } 
      else if(this.ConvertedToData.ratio) {
        return this.convRatioFixedDec + constTitle
      } 
      else {
        return 'AdaCalculator.com'
      }
    },
    convInputPlaceholder() {
      return this.Price.currency + ' amount...';
    },
    ...mapState({
      /* 
       * maps 
       *  this.Price
       * to:
       *  store.state.Price
       */
      Price: 'Price',
    }),
    ...mapGetters({
      /* 
       * maps 
       *  this.ConvertedToData
       * to:
       *  this.$store.getters.getSelectedConvData
       */
      ConvertedToData: 'getSelectedConvData',
      getCryptoCurrencies: 'getCryptoCurrencies',
      getFiatCurrencies: 'getFiatCurrencies',
    })
  },

  methods: {
    getFixedDecimalValue(inputNum, decimalPlaces = 6) {
      let num = Math.pow(10, decimalPlaces);
      let fixedDecimalValue = (((inputNum *= num) - (inputNum % 1)) / num);
      return fixedDecimalValue;
    },
    isNumber (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    customLabel ({ name, currency }) {
      return `${currency} - ${name}`
    },
    /* MUTATIONS: */
    toggleAutoRefresh () {
      this.$store.commit('toggleAutoRefresh');
    },
    /* ACTIONS: */
    fetchData () {
      this.$store.dispatch('fetchData');
    },
    autoFetchData (skip = false) {
      // console.log('autorefresh entered');
      if(this.Price.autoRefresh && !skip) { // prevents double api requests on init
        this.$store.dispatch('fetchData');
      }
      this.autoRefreshHandle = setTimeout(this.autoFetchData, 4000);
    },
  },
  destroyed () {
    clearTimeout(this.autoRefreshHandle) ;
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
