import Vue from 'vue';
import Vuex from 'vuex';

import axios from 'axios';

// TODO: replace 'vuex-persistedstate' with my own. Use:
// https://www.mikestreety.co.uk/blog/vue-js-using-localstorage-with-the-vuex-store
import createPersistedState from "vuex-persistedstate"; 

Vue.use(Vuex);

export default new Vuex.Store({

  strict: process.env.NODE_ENV !== 'production',

  plugins: [createPersistedState()],

  state: {
    Price: {
      api: 'CoinGecko',

      // availableAPIs: [
      //   'CoinGecko',
      //   'CoinMarketCap',
      // ],

      isUpdateAda: false,

      adaAmount: 1,

      convAmount: '',

      autoRefresh: true,

      //currencyType: 'Fiat',

      // availableCurrencyTypes: [
      //   'Cryptocurrency',
      //   'Fiat',
      // ],

      isFetching: false,

      currency: 'USD',

      apiData: { 
        /* 
         * stops missing data errors on root init
         * mirrors the API response structure
         * see:   api/example-output.json
         */
        currencies: [],
        metaData: {
          dateTimeOfRequest: '',
        },
      },
    },
    Staking: {
      showSubscribe: true,
      subscribeHiddenOn: '',
    },
  },

  mutations: { // api for changing state of the store
    /******** PRICE PAGE ********/
    setAdaAmount(state, payload) {
      state.Price.adaAmount = payload;
    },
    updateAdaAmount() {
      let newAdaAmount = calculateNewAdaAmount(this.state, this.getters);
      this.commit('setAdaAmount', newAdaAmount);
    },
    setConvAmount(state, payload) {
      state.Price.convAmount = payload;
    },
    updateConvAmount() {
      let newConvAmount = calculateNewConvAmount(this.state, this.getters);
      this.commit('setConvAmount', newConvAmount);
    },
    setIsUpdateAda(state, payload) {
      state.Price.isUpdateAda = payload;
    },
    setCurrency(state, payload) {
      state.Price.currency = payload;
      this.commit('updateConvAmount');
    },
    setIsFetching(state, payload) {
      state.Price.isFetching = payload;
    },
    storeResponse(state, payload) {
      state.Price.apiData = payload;
    },
    toggleAutoRefresh(state) {
      state.Price.autoRefresh = !state.Price.autoRefresh;
    },
    /******** STAKING PAGE ********/
    hideSubscribe(state, payload) {
      state.Staking.showSubscribe = false;
      state.Staking.subscribeHiddenOn = payload; // time in seconds
    },
    showSubscribe(state) {
      state.Staking.showSubscribe = true;
      state.Staking.subscribeHiddenOn = ''; // reset
    },
  },

  actions: { // like mutations for asynchronous actions (actions call mutations)
    fetchData({ commit }) {
      this.commit('setIsFetching', true);
      console.log(this.getters.getApiUrl)
      axios.get(this.getters.getApiUrl)
      .then((response) => {
        this.commit('storeResponse', response.data);

        if(this.state.Price.isUpdateAda) { // update adaAmount
          this.commit('updateAdaAmount');
        }
        else{ // update convAmount
          this.commit('updateConvAmount');
        }
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .then(() => {
        // always executed
        this.commit('setIsFetching', false);
      });
    },
  },

  getters: { // computed properties for the state
    getApiUrl: (state) => {
      let url = process.env.VUE_APP_API + 'api/v1/price/cardano?api=' 
      + state.Price.api;
      // + '&currency=' 
      // + state.Price.currency;
      return url;
    },
    getFiatCurrencies: (state) => {
      return state.Price.apiData.currencies.filter((currency) => {
        return currency.currencyType === 'Fiat';
      });
    },
    getCryptoCurrencies: (state) => {
      return state.Price.apiData.currencies.filter((currency) => {
        return currency.currencyType === 'Cryptocurrency';
      });
    },
    getSelectedConvData: (state) => {
      if (state.Price.apiData.currencies.length > 1) { // prevents errors on init
        return state.Price.apiData.currencies.filter((item) => {
          return item.currency === state.Price.currency; 
        })[0];
      } else return false
    },
  },
});

/***** HELPER FUNCTIONS *****/
function calculateNewAdaAmount(state, getters) {
  return state.Price.convAmount / getters.getSelectedConvData.ratio;
}
function calculateNewConvAmount(state, getters) {
  return state.Price.adaAmount * getters.getSelectedConvData.ratio;
}
