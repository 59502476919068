<template>

  <header class="SiteHeader">
    <h1 class="SiteHeader__Title">AdaCalculator.com</h1>
    <nav role="navigation" class="SiteHeaderNavbar">
      <router-link to="/">Price</router-link> | 
      <router-link to="/cardano-staking-calculator">Staking</router-link> | 
      <a href="https://adastaking.com/">Pool</a> | 
      <a href="https://buyada.com/">Buy ADA</a> | 
      <router-link to="/about">About</router-link>
    </nav>

    <div class="SiteHeader__social">
      <a href="https://twitter.com/ada_staking"><svg class="SocialIcons__twitter SocialIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg></a>
      <a href="https://reddit.com/user/cardanoumbrella"><svg class="SocialIcons__reddit SocialIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514.24 514.24"><path d="M176 289.089v.032h16zM336 289.089v.032h16z"/><path d="M514.24 241.121c0-35.296-28.704-64-64-64-14.112 0-27.52 4.608-38.464 12.96-35.712-24.8-82.496-39.584-132.256-43.328l26.848-62.976 78.112 18.24c2.496 24.16 22.72 43.104 47.52 43.104 26.464 0 48-21.536 48-48s-21.536-48-48-48c-16.768 0-31.52 8.672-40.096 21.76l-91.296-21.344c-7.744-1.76-15.328 2.176-18.4 9.312l-37.12 87.04c-53.728 1.856-104.928 16.992-143.584 43.36C90.976 181.441 77.92 177.121 64 177.121c-35.296 0-64 28.704-64 64 0 23.392 12.768 44.544 32.352 55.392-.256 2.816-.352 5.696-.352 8.608 0 88.224 100.48 160 224 160 123.488 0 224-71.776 224-160 0-2.496-.096-4.96-.256-7.424 20.864-10.624 34.496-32.288 34.496-56.576zm-82.24-160c8.8 0 16 7.168 16 16s-7.2 16-16 16-16-7.168-16-16 7.2-16 16-16zm-288 208c0-17.632 14.368-32 32-32s32 14.368 32 32c0 17.664-14.368 32-32 32s-32-14.336-32-32zm185.888 106.144c-22.08 15.968-48 23.968-73.888 23.968s-51.808-8-73.888-23.968c-7.168-5.184-8.768-15.2-3.584-22.336s15.2-8.736 22.336-3.584c32.992 23.84 77.28 23.904 110.272 0 7.136-5.152 17.12-3.616 22.336 3.584 5.184 7.168 3.552 17.152-3.584 22.336zM336 321.121c-17.664 0-32-14.336-32-32 0-17.632 14.336-32 32-32s32 14.368 32 32c0 17.664-14.336 32-32 32z"/></svg></a>
      <!-- <a href="mailto:adastaking@cardanoumbrella.com"><svg class="SocialIcons__subscribe SocialIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path></svg></a> -->
      <a href="https://github.com/cardanoumbrella"><svg class="SocialIcons__github SocialIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"></path></svg></a>
      <a href="https://adastaking.com/"><img src="/images/adastaking-icon.ico" alt="ADA Staking pool" title="Our Cardano Staking Pool" style="padding: 0 5px;"></a>
    </div>

  </header>

</template>


<script>
export default {
  // mounted () {
  //   console.log('siteheader loaded');
  // }
  render () {},
}
</script>
