<script>
export default {
  name: 'dynamic-title',
  props: ['title'], // passed in from parent - source should be a computed property
  created () {
    document.title = this.title;
  },
  watch: {
    title () {
      // only used when the title changes after page load
      document.title = this.title;
    }
  },
  render () {},
}
</script>