<template>
  <div id="AboutPage">
    <dynamic-title title="AdaCalculator.com - 404 Page not found"></dynamic-title>
    
    <div class="AboutPage__Top PageMain__Top">
      <site-header></site-header>

      <div class="AboutEntry">
        <h3 class="AboutEntry__Header">
          <span class="AboutEntry__Title solid-heading">404 Error - Page not found</span>
        </h3>
        <p class="AboutEntry__Body solid-background">
          This page does not exist.
          <br><br>Why not check out the <a href="https://adacalculator.com/cardano-staking-calculator" class="Util-bold">Cardano Staking Calculator</a>
        </p>
      </div>


    </div> <!-- End .AboutPage__Top PageMain__Top -->
  </div> <!-- End #AboutPage-->
</template>

<script>
/* 
 * @ is an alias to /src EG:
 * import HelloWorld from '@/components/Price/HelloWorld.vue';
 */


// import { mapState } from 'vuex'
// import { mapGetters } from 'vuex'
// import { mapMutations } from 'vuex'
// import { mapActions } from 'vuex'

export default {

  computed: {
  },

  methods: {
  },
};
</script>