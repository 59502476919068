import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import HeadTitle from './components/Global/HeadTitle.vue';
import SiteHeader from './components/Global/SiteHeader.vue';

import './assets/style/main.scss';


Vue.component('dynamic-title', HeadTitle);
Vue.component('site-header', SiteHeader);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
